<template>
  <!-- 公告详情 -->
  <div  :class="['noticeDetails']" ref="noticeDetails">
    <div class="noticeDetailsBox" ref="noticeDetailsBox" :style="[{'height':$route.name == 'homePageDetails'? height:null}]">
      <div class="notice-title" v-if="!$route.query.labelName">
        {{noticeData.newTitle}}
      </div>
      <div class="notice-date" v-if="!$route.query.labelName">{{timestampReturnDate(noticeData.publisherTime, 'datetime')}}</div>
      <div class="notice-content">
        <div class="notice-text" v-html="noticeData.newBody"></div>
      </div>
      <div class="notice-view" v-if="$route.query.style">
        <div class="n-view-left">
          <img class="viewIcon" src="../../assets/img/views.png" alt="">
          <span>{{noticeData.views||0}}</span>
        </div>
      </div>
      <div :class="['notice-footer', {'footer2': $route.query.style}]" v-if="isShowFoot" >
        <div class="n-foot-left">
          <img class="viewIcon" src="../../assets/img/views.png" alt="">
          <span>{{noticeData.views}}</span>
        </div>
        <div class="n-foot-right" @click="ShareEvent">
          <div class="share-area" >
            <img class="shareIcon" src="../../assets/img/share.png" alt="">
            <span>分享</span>
          </div>
        </div>
      </div>
      <!-- <div class="share-module" v-if="isShowShare">
        <div class="share-box">
          <div class="share-btn">
            <img v-for="(item, index) in imgArr" :key="index" @click="ShareEvent(item.name)" :src="item.url" alt="">
          </div>
          <div class="cancelBtn" @click="showEvent">取消</div>
        </div>
      </div> -->
      <van-share-sheet v-model:show="showShare" :options="shareList"  @select="handleShare" />
    </div>
  </div>
</template>

<script>
import way from '../../api/encapsulation'
import homeFooter from '@/components/home/homeFooter'
export default {
  name: 'noticeDetails',
  components: {
    homeFooter
  },
  data () {
    return {
      height: 'auto',
      pageH: 0,
      boxH: 0,
      footerH: 0,
      noticeData: {},
      imgArr: [
        {
          name: 'weChat',
          url: require('../../assets/img/weChat.png')
        },
        {
          name: 'wechatMoments',
          url: require('../../assets/img/friendArea.png')
        },
        {
          name: 'QQ',
          url: require('../../assets/img/QQ.png')
        }
      ],
      isShowFoot: false,
      showShare: false,
      shareList: [
        {
          name: '微信',
          icon: require('../../assets/img/weChat.png')
        },
        {
          name: '朋友圈',
          icon: require('../../assets/img/friendArea.png')
        },
        {
          name: 'QQ',
          icon: require('../../assets/img/QQ.png')
        }
      ],
      schoolInfo: {}
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (newVal, oldVal) {
        console.log(newVal, oldVal)
        if (newVal.name == 'homePageDetails') {
          if (newVal.query.labelName) {
            this.$nextTick(() => {
              this.$set(this.noticeData, 'newTitle', newVal.query.labelName)
              this.$set(this.noticeData, 'newBody', newVal.query.briefIntroduction)
              this.$set(this.noticeData, 'publisherTime', newVal.query.createTime)
            })
          } else {
          }
          if (sessionStorage.getItem('schoolInfo')) {
            console.log(JSON.parse(sessionStorage.getItem('schoolInfo')), 'ddd')
            this.$set(this, 'schoolInfo', JSON.parse(sessionStorage.getItem('schoolInfo')))
          }
        }
      }
    },
    schoolInfo: {
      immediate: true,
      handler (newV, odlV) {
        console.log(newV, 'ddd333')
        this.$nextTick(() => {
          setTimeout(() => {
            console.log(this.$refs.noticeDetails.clientHeight, 'this.$pageH')
            console.log(this.$refs.homeFooter.$el.clientHeight, 'this.$footerH')
            this.pageH = this.$refs.noticeDetails.clientHeight
            this.footerH = this.$refs.homeFooter.$el.clientHeight
            if (this.boxH < (this.pageH - this.footerH - 32) && this.boxH !== 0) {
              console.log(this.height, 'hhh')
              this.height = String(this.pageH - this.footerH - 32) + 'px'
              console.log(this.height, 'hhh2')
            }
          }, 400)
        })
      }
    },
    boxH: {
      handler (newV, odlV) {
        console.log(newV, 'height')
        if (this.boxH < (this.pageH - this.footerH - 32) && this.boxH !== 0) {
          console.log(this.height, 'bbb')
          this.height = String(this.pageH - this.footerH - 32) + 'px'
          console.log(this.height, 'bbb2')
        }
      }
    }
  },
  mounted () {
    const id = this.$route.query.id
    if (this.$route.query.labelName) {
      // this.noticeData.newTitle = this.$route.query.labelName
      // this.noticeData.newBody = this.$route.query.briefIntroduction
      // this.noticeData.publisherTime = this.$route.query.createTime
      // this.$set(this.noticeData,'newTitle',this.$route.query.labelName)
      // this.$set(this.noticeData,'newBody',this.$route.query.briefIntroduction)
      // this.$set(this.noticeData,'publisherTime',this.$route.query.createTime)
      // console.log(this.noticeData,'noticeData');
      // this.isShowFoot = true
    } else if (id) {
      if (sessionStorage.getItem('schoolInfo')) {
        const schoolInfo = JSON.parse(sessionStorage.getItem('schoolInfo'))
        if (schoolInfo.id && schoolInfo.id !== sessionStorage.getItem('tenant_id')) {
          sessionStorage.setItem('tenant_id', schoolInfo.id)
        }
      }
      this.getNoticeDetails(id)
    }
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    getNoticeDetails (newId) {
      this.$request.getNewsById({ newId }).then(res => {
        if (res.data.code == 0) {
          this.noticeData = res.data.data
          if (this.$route.query.style == 'h5') {
            this.isShowFoot = false
            setTimeout(() => {
              this.$nextTick(() => {
                this.boxH = this.$refs.noticeDetailsBox.scrollHeight
                console.log(this.$refs, 'this.$ref2', this.pageH)
              })
            }, 200)
          } else {
            this.isShowFoot = true
          }
        } else if (res.data.code == 1) {
          this.isShowFoot = false
          this.Info(res.data.msg)
        }
      })
    },
    // showEvent() {
    //   this.isShowShare = !this.isShowShare;
    // },
    ShareEvent () {
      const id = this.$route.query.id
      const linkUrl = window.location.origin + '/#/noticeAnnouncement/noticeDetails?id=' + id
      const shareType = {
        title: this.noticeData.newTitle, // 标题
        // summary: this.noticeData.summary, // 摘要
        linkUrl // 链接
      }
      const type = this.$store.state.deviceType
      console.log(type, 'type')
      if (type == 1) {
        window.webkit.messageHandlers.sharingApproach.postMessage(JSON.stringify(shareType))
      } else if (type == 2) {
        // window.Android.sharingApproach(shareType)
        const tempData = {
          name: 'sharingApproach',
          value: shareType
        }
        window.Flutter.postMessage(JSON.stringify(tempData))
      } else {
        console.log(type, 'type2')
        this.showShare = true
      }
    },
    // h5分享事件
    handleShare (a) {
      console.log(a)
    }
  }
}
</script>

<style lang='scss' scoped>
  .noticeDetails {
    height: 100%;
    background-color: #F9F9F9;
    // background-color: #fff;
    overflow: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    .noticeDetailsBox {
      padding: 16px 24px;
      // clear: both;
      .notice-title {
        font-size: 18px;
        color: #333;
        text-align: center;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
      }
      .notice-date {
        font-size: 14px;
        color: #999999;
        margin: 16px 0;
        text-align: left;
      }
      .notice-content {
        .notice-text {
          word-break: break-all;
          font-size: 16px;
          ::v-deep img {
            width:100% !important;
          }
          p {
            word-break: break-all;
          }
        }
        padding-bottom: 50px;
      }
      .notice-view{
        display: flex;
        justify-content: flex-end;
        .n-view-left{
          // width: 14px;
          display: flex;
          align-items: center;
          margin-top: 30px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          img {
            margin-right: 4px;
            width: 20px;
            height: 13px;
          }
        }
      }
      .notice-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // height: 56px;
        background: #FFFFFF;
        position: fixed;
        width: calc(100% - 48px);
        left: 0;
        bottom: 0;
        padding: 18px 24px;
        font-size: 14px !important;
        color: #999999;
        z-index: 10;
        height: 20px;
        &.footer2{
          justify-content: flex-end;
          .n-foot-right{
            display: none;
          }
        }
        .n-foot-left {
          display: flex;
          align-items: center;
        }
        .n-foot-right {
        }
        .viewIcon {
          width: 20px !important;
          height: 13px !important;
          margin-right: 6px;
        }
        .share-area {
          display: flex;
          align-items: center;
        }
        .shareIcon {
          width: 20px !important;
          height: 20px !important;
          margin-right: 6px;
        }
        // span {
        //   font-size: 14px;
        //   color: #999999;
        // }
      }
      .share-module {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);
        .share-box {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 163px;
          border-radius: 10px 10px 0px 0px;
          .share-btn {
            height: 107px;
            display: flex;
            align-items: center;
            background: #F9F9F9;
            & > img {
              width: 44px !important;
              height: 44px !important;
              margin: 0 12px;
            }
            & > img:first-of-type {
              margin-left: 24px;
            }
          }
          .cancelBtn {
            font-size: 16px;
            color: #333333;
            height: 56px;
            line-height: 56px;
            text-align: center;
            background-color: #fff;
          }
        }
      }
    }
  }
</style>
